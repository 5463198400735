import { Link } from '@remix-run/react'

const navigation = {
	classes: [
		{ name: 'Class Schedule', href: '/classes/monday' },
		// { name: "Summer 2023-24", href: "#" },
		{ name: 'Programs & Policies', href: '/class-definitions' },
		{ name: 'Registration', href: '/registration' },
	],
	studio: [
		{ name: 'About Us', href: '/about' },
		{ name: 'Faculty', href: '/faculty' },
		{ name: 'Annual Production', href: '/production' },
		{ name: 'Performance Co.', href: '/company' },
		// { name: 'Squads', href: '#' },
	],
	resources: [
		{
			name: 'Parent Portal',
			href: 'https://clients.dancestudiomanager.com/sdda/client/',
		},
		{ name: 'Hours & Important Dates', href: '/dates' },
		{ name: 'Contact', href: '/contact' },
	],

	social: [
		{
			name: 'Facebook',
			href: 'https://www.facebook.com/profile.php?id=100063796800184',
			icon: (
				props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
			) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: 'Instagram',
			href: 'https://www.instagram.com/stagedoordanceacademy/?hl=en',
			icon: (
				props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
			) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
	],
}

export default function Footer() {
	return (
		<>
			<div className="h-1 bg-stone-600" />
			<div className="relative isolate overflow-hidden bg-stone-900 pb-24">
				<div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
					<svg
						viewBox="0 0 1313 771"
						aria-hidden="true"
						className="ml-[max(50%,38rem)] w-[82.0625rem]"
					>
						<path
							id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
							fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
							d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
						/>
						<defs>
							<linearGradient
								id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
								x1="1313.17"
								x2="-88.881"
								y1=".201"
								y2="539.417"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#9089FC" />
								<stop offset={1} stopColor="#FF80B5" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end">
					<svg
						viewBox="0 0 1313 771"
						aria-hidden="true"
						className="ml-[-22rem] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] xl:ml-0 xl:mr-[calc(50%-12rem)]"
					>
						<use href="#bc169a03-3518-42d4-ab1e-d3eadac65edc" />
					</svg>
				</div>
				<footer aria-labelledby="footer-heading">
					<h2 id="footer-heading" className="sr-only">
						Footer
					</h2>
					<div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
						<div className="xl:grid xl:grid-cols-3 xl:gap-8">
							<div>
								<img
									className="h-16 w-auto md:h-24"
									src="/images/sdda-logo-without-circle-white.png"
									alt="Stage Door Dance Academy"
								/>
							</div>
							<div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
								<div className="md:grid md:grid-cols-2 md:gap-8">
									<div>
										<h3 className="text-sm font-semibold leading-6 text-white">
											Classes
										</h3>
										<ul className="mt-6 space-y-4">
											{navigation.classes.map((item) => (
												<li key={item.name}>
													<a
														href={item.href}
														className="text-sm leading-6 text-gray-300 hover:text-white"
													>
														{item.name}
													</a>
												</li>
											))}
										</ul>
									</div>
									<div className="mt-10 md:mt-0">
										<h3 className="text-sm font-semibold leading-6 text-white">
											Studio
										</h3>
										<ul className="mt-6 space-y-4">
											{navigation.studio.map((item) => (
												<li key={item.name}>
													<a
														href={item.href}
														className="text-sm leading-6 text-gray-300 hover:text-white"
													>
														{item.name}
													</a>
												</li>
											))}
										</ul>
									</div>
								</div>
								<div className="md:grid md:grid-cols-2 md:gap-8">
									<div>
										<h3 className="text-sm font-semibold leading-6 text-white">
											Resources
										</h3>
										<ul className="mt-6 space-y-4">
											{navigation.resources.map((item) =>
												item.href.startsWith('http') ? (
													<li key={item.name}>
														<a
															href={item.href}
															target="_blank"
															className="text-sm leading-6 text-gray-300 hover:text-white"
															rel="noreferrer"
														>
															{item.name}
														</a>
													</li>
												) : (
													<li key={item.name}>
														<Link
															to={item.href}
															className="text-sm leading-6 text-gray-300 hover:text-white"
														>
															{item.name}
														</Link>
													</li>
												),
											)}
											{/* <li key={item.name}>
                          <a
                            href={item.href}
                            className="text-sm leading-6 text-gray-300 hover:text-white"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))} */}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
							<div>
								<h3 className="text-sm font-semibold leading-6 text-white">
									Contact us for a free class!
								</h3>
								<p className="mt-2 max-w-2xl text-sm leading-6 text-gray-300">
									If you are interested in joining our studio, we would love to
									offer you a free trial class.
								</p>
							</div>
							<div className="mt-6 sm:flex sm:max-w-md lg:mt-0">
								<div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
									<a
										href="https://docs.google.com/forms/d/e/1FAIpQLSfTuUaMX2hJsdzAm4cDISNFoKhchbC751csuPG86WQlvgbcRw/viewform"
										target="_blank"
										rel="noreferrer"
									>
										<button
											type="submit"
											className="flex w-full items-center justify-center rounded-md bg-[#f9c04d] px-3 py-2 text-base font-semibold text-stone-900 shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#f9c04d]"
										>
											Get your FREE class today!
										</button>
									</a>
								</div>
							</div>
						</div>
						<div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
							<div className="flex space-x-6 md:order-2">
								{navigation.social.map((item) => (
									<a
										key={item.name}
										href={item.href}
										target="_blank"
										className="text-gray-500 hover:text-gray-400"
										rel="noreferrer"
									>
										<span className="sr-only">{item.name}</span>
										<item.icon className="h-6 w-6" aria-hidden="true" />
									</a>
								))}
							</div>
							<p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
								&copy; {new Date().getFullYear()} Stage Door Dance Academy. All
								rights reserved.
							</p>
						</div>
					</div>
				</footer>
			</div>
		</>
	)
}
