import {
	Popover,
	PopoverButton,
	PopoverGroup,
	PopoverPanel,
	Transition,
} from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from '@remix-run/react'
import { Fragment, useState } from 'react'
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet'

const classes = [
	{
		name: 'Class Schedule',
		description: '2024-25 season.',
		href: '/classes/monday',
	},
	// {
	//   name: "Summer 2023-24",
	//   description: "Summer class schedule.",
	//   href: "/summer-classes",
	// },
	{
		name: 'Programs & Policies',
		description: 'Class definitions, requirements and policies.',
		href: '/class-definitions',
	},
	{
		name: 'Registration',
		description: 'Registration for 2024-25.',
		href: '/registration',
	},
]
const studio = [
	{
		name: 'About Us',
		description: 'We love what we do!',
		href: '/about',
	},
	{
		name: 'Faculty',
		description: 'Meet our teachers.',
		href: '/faculty',
	},
	{
		name: 'Annual Production',
		description: 'We look forward to celebrating our dancers each year.',
		href: '/production',
	},
	{
		name: 'Performance Company',
		description: 'Our competitive dance team.',
		href: '/company',
	},
	// {
	// 	name: 'Squads',
	// 	description: '',
	// 	href: '#',
	// },
]
const resources = [
	{
		name: 'Parent Portal',
		description: '',
		href: 'https://clients.dancestudiomanager.com/sdda/client/',
	},
	{
		name: 'Hours & Important Dates',
		description: '',
		href: '/dates',
	},
	{
		name: 'Contact Us',
		description: '',
		href: '/contact',
	},
]

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function Header() {
	const [open, setOpen] = useState(false)
	return (
		<div className="absolute inset-0">
			<div className="flex w-screen">
				<Popover className="relative z-50 w-full bg-transparent">
					<div className="flex items-center justify-between px-4 py-6 sm:px-12 lg:justify-start lg:space-x-8">
						<div className="flex justify-start lg:w-0 lg:flex-1">
							<Link to="/">
								<span className="sr-only">Stage Door Dance Academy</span>
								<img
									className="h-14"
									src="/images/sdda-logo-without-circle-white.png"
									alt=""
								/>
							</Link>
						</div>
						<div className="lg:hidden">
							<Sheet open={open} onOpenChange={setOpen}>
								<SheetTrigger asChild>
									<Bars3Icon
										className="h-6 w-6 text-[#f9c04d]"
										aria-hidden="true"
									/>
								</SheetTrigger>
								<SheetContent>
									<div className="rounded-lg border border-[#f9c04d] bg-stone-800 shadow-lg ring-1 ring-black ring-opacity-5">
										<div className="px-5 pb-6 pt-5">
											<nav>
												<div className="grid grid-cols-1">
													<h2 className="text-base font-bold uppercase text-[#f9c04d]">
														Classes
													</h2>
													{classes.map((c) => (
														<Link
															key={c.name}
															to={c.href}
															className="flex items-center rounded-lg p-2 hover:hover:bg-stone-900"
														>
															<div className="ml-4 text-sm font-medium text-white">
																<button onClick={() => setOpen(false)}>
																	{c.name}
																</button>
															</div>
														</Link>
													))}
												</div>
												<div className="mt-2 grid grid-cols-1">
													<h2 className="text-base font-bold uppercase text-[#f9c04d]">
														Studio
													</h2>
													{studio.map((s) => (
														<Link
															key={s.name}
															to={s.href}
															className="flex items-center rounded-lg p-2 hover:hover:bg-stone-900"
														>
															<div className="ml-4 text-sm font-medium text-stone-100">
																<button onClick={() => setOpen(false)}>
																	{s.name}
																</button>
															</div>
														</Link>
													))}
												</div>
												<div className="mt-2 grid grid-cols-1">
													<h2 className="text-base font-bold uppercase text-[#f9c04d]">
														Resources
													</h2>
													{resources.map((r) =>
														r.href.includes('http') ? (
															<a
																key={r.name}
																href={r.href}
																target="_blank"
																className="flex items-center rounded-lg p-2 hover:hover:bg-stone-900"
																rel="noreferrer"
															>
																<div className="ml-4 text-sm font-medium text-stone-100">
																	<button onClick={() => setOpen(false)}>
																		{r.name}
																	</button>
																</div>
															</a>
														) : (
															<Link
																key={r.name}
																to={r.href}
																className="flex items-center rounded-lg p-2 hover:hover:bg-stone-900"
															>
																<div className="ml-4 text-sm font-medium text-stone-100">
																	<button onClick={() => setOpen(false)}>
																		{r.name}
																	</button>
																</div>
															</Link>
														),
													)}
												</div>
											</nav>
										</div>
										<div className="px-5 py-4">
											<div>
												<Link
													to="/registration"
													className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#f9c04d] px-4 py-2 text-sm font-medium text-stone-900 shadow-sm hover:bg-opacity-90"
												>
													<button onClick={() => setOpen(false)}>
														Registration
													</button>
												</Link>
											</div>
										</div>
									</div>
								</SheetContent>
							</Sheet>
						</div>
						<PopoverGroup as="nav" className="hidden space-x-5 lg:flex">
							<Popover className="relative">
								{({ open }) => (
									<>
										<PopoverButton
											className={classNames(
												open ? 'text-stone-200' : 'text-stone-50',
												'group inline-flex items-center rounded-md px-2 text-base font-medium text-stone-200 hover:text-stone-200 focus:outline-none',
											)}
										>
											<span>Classes</span>
											<ChevronDownIcon
												className={classNames(
													open ? 'text-stone-200' : 'text-stone-50',
													'ml-2 h-5 w-5 group-hover:text-stone-200',
												)}
												aria-hidden="true"
											/>
										</PopoverButton>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<PopoverPanel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
												<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{classes.map((item) => (
															<Link
																key={item.name}
																to={item.href}
																className="-m-3 block rounded-md p-3 hover:bg-stone-50"
															>
																<PopoverButton as="div">
																	<p className="text-base font-medium text-stone-900">
																		{item.name}
																	</p>
																	<p className="mt-1 text-sm text-stone-500">
																		{item.description}
																	</p>
																</PopoverButton>
															</Link>
														))}
													</div>
												</div>
											</PopoverPanel>
										</Transition>
									</>
								)}
							</Popover>
							<Popover className="relative">
								{({ open }) => (
									<>
										<PopoverButton
											className={classNames(
												open ? 'text-stone-200' : 'text-stone-50',
												'group inline-flex items-center rounded-md px-2 text-base font-medium text-stone-200 hover:text-stone-200 focus:outline-none',
											)}
										>
											<span>Studio</span>
											<ChevronDownIcon
												className={classNames(
													open ? 'text-stone-200' : 'text-stone-50',
													'ml-2 h-5 w-5 group-hover:text-stone-200',
												)}
												aria-hidden="true"
											/>
										</PopoverButton>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<PopoverPanel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
												<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{studio.map((s) => (
															<Link
																key={s.name}
																to={s.href}
																className="-m-3 block rounded-md p-3 hover:bg-stone-50"
															>
																<PopoverButton as="div">
																	<p className="text-base font-medium text-stone-900">
																		{s.name}
																	</p>
																	<p className="mt-1 text-sm text-stone-500">
																		{s.description}
																	</p>
																</PopoverButton>
															</Link>
														))}
													</div>
												</div>
											</PopoverPanel>
										</Transition>
									</>
								)}
							</Popover>
							<Popover className="relative">
								{({ open }) => (
									<>
										<PopoverButton
											className={classNames(
												open ? 'text-stone-200' : 'text-stone-50',
												'group inline-flex items-center rounded-md px-2 text-base font-medium text-stone-200 hover:text-stone-200 focus:outline-none',
											)}
										>
											<span>Resources</span>
											<ChevronDownIcon
												className={classNames(
													open ? 'text-stone-200' : 'text-stone-50',
													'ml-2 h-5 w-5 group-hover:text-stone-200',
												)}
												aria-hidden="true"
											/>
										</PopoverButton>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<PopoverPanel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
												<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
													<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
														{resources.map((r) =>
															r.href.includes('http') ? (
																<a
																	key={r.name}
																	href={r.href}
																	target="_blank"
																	className="-m-3 block rounded-md p-3 hover:bg-stone-50"
																	rel="noreferrer"
																>
																	<PopoverButton as="div">
																		<p className="text-base font-medium text-stone-900">
																			{r.name}
																		</p>
																		<p className="mt-1 text-sm text-stone-500">
																			{r.description}
																		</p>
																	</PopoverButton>
																</a>
															) : (
																<Link
																	key={r.name}
																	to={r.href}
																	className="-m-3 block rounded-md p-3 hover:bg-stone-50"
																>
																	<PopoverButton as="div">
																		<p className="text-base font-medium text-stone-900">
																			{r.name}
																		</p>
																		<p className="mt-1 text-sm text-stone-500">
																			{r.description}
																		</p>
																	</PopoverButton>
																</Link>
															),
														)}
													</div>
												</div>
											</PopoverPanel>
										</Transition>
									</>
								)}
							</Popover>
						</PopoverGroup>
						<div className="hidden items-center justify-end lg:flex lg:w-0 lg:flex-1">
							<Link
								to="/registration"
								className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#f9c04d] px-4 py-2 text-sm font-medium text-stone-900 shadow-sm hover:hover:opacity-90"
							>
								Registration
							</Link>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	)
}
